import React, { useState } from 'react';
import useOrders from '../../firebase files/hooks/useOrders';
import { useAuthContext } from '../../firebase files/hooks/useAuthContext';
import './QuickPickup.scss';
// import QuickPickupSummary from './QuickPickupSummary';
import { useNavigate } from 'react-router-dom';

const CLOTHS_QUANTITY_OPTIONS = [
    '1-5 pieces',
    '6-10 pieces',
    '11-15 pieces',
    '15+ pieces'
];

const QuickPickup = () => {
    const navigate = useNavigate();
    const { createQuickPickup } = useOrders();
    const { user } = useAuthContext();
    const [selectedQuantity, setSelectedQuantity] = useState('');
    const [name, setName] = useState(user?.fullName || '');
    const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '');
    const [address, setAddress] = useState({
        streetLineOne: '',
        streetLineTwo: '',
        landmark: '',
        city: '',
        pincode: '',
        state: '',
        country: 'India'
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showAddressModal, setShowAddressModal] = useState(false);

    const handleAddressSelect = (selectedAddress) => {
        setAddress(selectedAddress);
        setShowAddressModal(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const result = await createQuickPickup(address, selectedQuantity);
            if (result) {
                console.log('Quick pickup created successfully!');
                console.log(result)
                navigate(`/quick-pickup-summary/${result.id}`);
            }
        } catch (err) {
            setError('Failed to create quick pickup order. Please try again.');
            console.error(err);
        }
        setLoading(false);
    };

    const AddressModal = () => (
        <div className="smart-wash-address-modal-overlay">
            <div className="smart-wash-address-modal">
                <h3>Select Address</h3>
                <div className="smart-wash-address-list">
                    {user?.addresses?.map((addr, index) => (
                        <div 
                            key={index} 
                            className="smart-wash-address-item"
                            onClick={() => handleAddressSelect(addr)}
                        >
                            <p>{addr.houseNumber} ,{addr.streetLineOne} ,{addr.streetLineTwo} ,{addr.landmark} ,{addr.city} ,{addr.state} ,{addr.pincode}</p>
                        </div>
                    ))}
                </div>
                <div className="smart-wash-modal-actions">
                    <button 
                        className="smart-wash-cancel-btn"
                        onClick={() => setShowAddressModal(false)}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );

    return (
        <div className="smart-wash-quick-pickup">
            <h1>Quick Pickup</h1>
            <form onSubmit={handleSubmit}>
                <div className="smart-wash-form-section">
                    <h3>Personal Details</h3>
                    <div className="smart-wash-info-display">
                        <div className="smart-wash-info-item">
                            <label>Name</label>
                            <span>{user?.fullName || 'Not provided'}</span>
                        </div>
                        <div className="smart-wash-info-item">
                            <label>Phone</label>
                            <span>{user?.phoneNumber || 'Not provided'}</span>
                        </div>
                    </div>
                </div>

                <div className="smart-wash-quantity-selector">
                    <h3>Expected Number of Clothes</h3>
                    <select 
                        value={selectedQuantity}
                        onChange={(e) => setSelectedQuantity(e.target.value)}
                        required
                    >
                        <option value="">Select quantity range</option>
                        {CLOTHS_QUANTITY_OPTIONS.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>
                </div>

                <div className="smart-wash-address-form">
                    <div className="smart-wash-address-header">
                        <h3>Pickup Address</h3>
                        <button 
                            type="button" 
                            className="existing-address-btn"
                            onClick={() => setShowAddressModal(true)}
                        >
                            Choose from existing
                        </button>
                    </div>
                    <input
                        type="text"
                        placeholder="House Number"
                        value={address.houseNumber}
                        onChange={(e) => setAddress({...address, houseNumber: e.target.value})}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Street Address Line 1"
                        value={address.streetLineOne}
                        onChange={(e) => setAddress({...address, streetLineOne: e.target.value})}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Street Address Line 2"
                        value={address.streetLineTwo}
                        onChange={(e) => setAddress({...address, streetLineTwo: e.target.value})}
                    />
                    <input
                        type="text"
                        placeholder="Landmark"
                        value={address.landmark}
                        onChange={(e) => setAddress({...address, landmark: e.target.value})}
                    />
                    <div className="smart-wash-input-group">
                        <input
                            type="text"
                            placeholder="City"
                            value={address.city}
                            onChange={(e) => setAddress({...address, city: e.target.value})}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Pincode"
                            value={address.pincode}
                            onChange={(e) => setAddress({...address, pincode: e.target.value})}
                            required
                        />
                    </div>
                    <input
                        type="text"
                        placeholder="State"
                        value={address.state}
                        onChange={(e) => setAddress({...address, state: e.target.value})}
                        required
                    />
                </div>

                {error && <div className="smart-wash-error">{error}</div>}
                
                <button className="smart-wash-quick-pickup-submit-btn" type="submit" disabled={loading}>
                    {loading ? 'Creating Quick Pickup...' : 'Create Quick Pickup'}
                </button>
            </form>

            {showAddressModal && <AddressModal />}
        </div>
    );
};

export default QuickPickup;