import React,{useState,useEffect} from 'react';
import './Reorder.scss';
import {useParams, useNavigate} from 'react-router-dom';
import useOrders from '../../firebase files/hooks/useOrders';
import {useCollection} from '../../firebase files/hooks/useCollection';

const Reorder = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const {reorderFromExisting} = useOrders();
    const {documents:ordersData,error:ordersError} = useCollection('orders');
    const {documents:products} = useCollection('products');

    const [orderToBeReordered,setOrderToBeReordered] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Filter products based on search term
    useEffect(() => {
        if (products && searchTerm) {
            const filtered = products.filter(product => 
                product.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredProducts(filtered);
        } else {
            setFilteredProducts([]);
        }
    }, [searchTerm, products]);

    // Get original order details
    useEffect(()=>{
        if(ordersData){
            const orderFound = ordersData.find((order)=>order.id === id);
            if(orderFound){
                setOrderToBeReordered(orderFound);
                // Initialize selected products with the original order's products
                setSelectedProducts(orderFound.products.map(product => ({
                    ...product,
                    quantity: product.quantity || 1
                })));
            }
        }
    },[ordersData,id]);

    const handleProductSelect = (product) => {
        if (!selectedProducts.find(p => p.id === product.id)) {
            setSelectedProducts([...selectedProducts, {
                ...product,
                quantity: 1
            }]);
        }
        setSearchTerm('');
        setFilteredProducts([]);
    };

    const updateQuantity = (productId, change) => {
        setSelectedProducts(prevProducts => 
            prevProducts.map(product => 
                product.id === productId 
                    ? { ...product, quantity: Math.max(1, product.quantity + change) }
                    : product
            )
        );
    };

    const removeProduct = (productId) => {
        setSelectedProducts(prevProducts => 
            prevProducts.filter(product => product.id !== productId)
        );
    };

    const handleReorder = async () => {
        if (selectedProducts.length === 0) {
            setError('Please add at least one product');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            await reorderFromExisting(
                selectedProducts,
                orderToBeReordered.shippingAddress,
                orderToBeReordered.paymentMethod
            );
            navigate('/orders');
        } catch (err) {
            setError('Failed to create reorder. Please try again.');
            console.error(err);
        }
        setLoading(false);
    };

    const totalAmount = selectedProducts.reduce(
        (sum, product) => sum + (product.price * product.quantity), 
        0
    );

    if (!orderToBeReordered) {
        return <div>Loading...</div>;
    }

    return (
        <div className="smart-wash-reorder-container">
            <div className="header">
                <h1>Reorder from Order #{id}</h1>
                <p>Modify products and quantities as needed</p>
            </div>

            <div className="order-details">
                <h2>Order Details</h2>
                <div className="details-grid">
                    <div>
                        <strong>Shipping Address:</strong>
                        <p>{orderToBeReordered.shippingAddress.houseNumber}</p>
                        <p>{orderToBeReordered.shippingAddress.streetLineOne}</p>
                        <p>{orderToBeReordered.shippingAddress.streetLineTwo}</p>
                        <p>{orderToBeReordered.shippingAddress.landmark}</p>
                        <p>{orderToBeReordered.shippingAddress.city}</p>
                        <p>{orderToBeReordered.shippingAddress.state}</p>
                        <p>{orderToBeReordered.shippingAddress.pincode}</p>
                    </div>
                    <div>
                        <strong>Payment Method:</strong>
                        <p>{orderToBeReordered.paymentMethod}</p>
                    </div>
                </div>
            </div>

            <div className="search-section">
                <div className="search-container">
                    <span className="material-symbols-outlined">search</span>
                    <input
                        type="text"
                        placeholder="Search products to add..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                {filteredProducts.length > 0 && (
                    <div className="search-results">
                        {filteredProducts.map(product => (
                            <div 
                                key={product.id} 
                                className="search-result-item"
                                onClick={() => handleProductSelect(product)}
                            >
                                <img src={product.images[0]} alt={product.name} />
                                <div className="product-info">
                                    <h3>{product.name}</h3>
                                    <p>₹{product.price}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="selected-products">
                <h2>Selected Products</h2>
                {selectedProducts.map(product => (
                    <div key={product.id} className="product-item">
                        <img src={product.images[0]} alt={product.name} />
                        <div className="product-details">
                            <h3>{product.name}</h3>
                            <p>₹{product.price}</p>
                        </div>
                        <div className="quantity-controls">
                            <button onClick={() => updateQuantity(product.id, -1)}>-</button>
                            <span>{product.quantity}</span>
                            <button onClick={() => updateQuantity(product.id, 1)}>+</button>
                        </div>
                        <button 
                            className="remove-btn"
                            onClick={() => removeProduct(product.id)}
                        >
                            Remove
                        </button>
                    </div>
                ))}
            </div>

            {error && <div className="error-message">{error}</div>}

            <div className="order-summary">
                <div className="total">
                    <strong>Total Amount:</strong>
                    <span>₹{totalAmount}</span>
                </div>
                <button 
                    className="reorder-btn"
                    onClick={handleReorder}
                    disabled={loading || selectedProducts.length === 0}
                >
                    {loading ? 'Processing...' : 'Place Reorder'}
                </button>
            </div>
        </div>
    );
}

export default Reorder;