import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useCollection } from '../../firebase files/hooks/useCollection';
import useOrders from '../../firebase files/hooks/useOrders';
import './QuickPickupProductAdd.scss';

const QuickPickupProductAdd = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { documents: products } = useCollection('products');
    const { updateOrderProducts, orders } = useOrders();
    
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Filter products based on search term
    useEffect(() => {
        if (products && searchTerm) {
            const filtered = products.filter(product => 
                product.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredProducts(filtered);
        } else {
            setFilteredProducts([]);
        }
    }, [searchTerm, products]);

    const handleProductSelect = (product) => {
        if (!selectedProducts.find(p => p.id === product.id)) {
            setSelectedProducts([...selectedProducts, {
                ...product,
                quantity: 1
            }]);
        }
        setSearchTerm('');
        setFilteredProducts([]);
    };

    const updateQuantity = (productId, change) => {
        setSelectedProducts(prevProducts => 
            prevProducts.map(product => 
                product.id === productId 
                    ? { ...product, quantity: Math.max(1, product.quantity + change) }
                    : product
            )
        );
    };

    const removeProduct = (productId) => {
        setSelectedProducts(prevProducts => 
            prevProducts.filter(product => product.id !== productId)
        );
    };

    const handleSubmit = async () => {
        if (selectedProducts.length === 0) {
            setError('Please add at least one product');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            await updateOrderProducts(id, selectedProducts);
            navigate(`/orders`);
        } catch (err) {
            setError('Failed to update products. Please try again.');
            console.error(err);
        }
        setLoading(false);
    };

    const totalAmount = selectedProducts.reduce(
        (sum, product) => sum + (product.price * product.quantity), 
        0
    );

    return (
        <div className="quick-pickup-product-add">
            <div className="header">
                <h1>Add Products to Order #{id}</h1>
                <p>Search and add products to this quick pickup order</p>
            </div>

            <div className="search-section">
                <div className="search-container">
                    <span className="material-symbols-outlined">search</span>
                    <input
                        type="text"
                        placeholder="Search products..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                {filteredProducts.length > 0 && (
                    <div className="search-results">
                        {filteredProducts.map(product => (
                            <div 
                                key={product.id} 
                                className="search-result-item"
                                onClick={() => handleProductSelect(product)}
                            >
                                <img src={product.images[0]} alt={product.name} />
                                <div className="product-info">
                                    <h3>{product.name}</h3>
                                    <p>₹{product.price}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="selected-products">
                <h2>Selected Products</h2>
                {selectedProducts.length === 0 ? (
                    <div className="empty-state">
                        <span className="material-symbols-outlined">shopping_cart</span>
                        <p>No products added yet</p>
                    </div>
                ) : (
                    <div className="product-list">
                        {selectedProducts.map(product => (
                            <div key={product.id} className="product-item">
                                <img src={product.images[0]} alt={product.name} />
                                <div className="product-details">
                                    <h3>{product.name}</h3>
                                    <p className="price">₹{product.price}</p>
                                    <div className="quantity-controls">
                                        <button onClick={() => updateQuantity(product.id, -1)}>-</button>
                                        <span>{product.quantity}</span>
                                        <button onClick={() => updateQuantity(product.id, 1)}>+</button>
                                    </div>
                                </div>
                                <div className="product-total">
                                    <p>₹{product.price * product.quantity}</p>
                                </div>
                                <button 
                                    className="remove-button"
                                    onClick={() => removeProduct(product.id)}
                                >
                                    <span className="material-symbols-outlined">delete</span>
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {selectedProducts.length > 0 && (
                <div className="order-summary">
                    <div className="total">
                        <span>Total Amount:</span>
                        <span>₹{totalAmount}</span>
                    </div>
                </div>
            )}

            {error && <div className="error-message">{error}</div>}

            <div className="action-buttons">
                <button 
                    className="cancel-button"
                    onClick={() => navigate('/orders')}
                >
                    Cancel
                </button>
                <button 
                    className="save-button"
                    onClick={handleSubmit}
                    disabled={loading || selectedProducts.length === 0}
                >
                    {loading ? 'Saving...' : 'Save Products'}
                </button>
            </div>
        </div>
    );
};

export default QuickPickupProductAdd;
