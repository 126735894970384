import React, { useState, useEffect } from 'react';
import './QuickPickupSummary.scss';
import { useParams, useNavigate } from 'react-router-dom';   
import useOrders from '../../firebase files/hooks/useOrders';
import { useAuthContext } from '../../firebase files/hooks/useAuthContext';

const QuickPickupSummary = () => {
    const { id } = useParams();
    const { orders } = useOrders();
    const [order, setOrder] = useState(null);
    const { user } = useAuthContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (orders && orders.length > 0) {
            const foundOrder = orders.find(order => order.id === id);
            setOrder(foundOrder);
        }
    }, [orders, id]);

    if (!order) {
        return <div>Loading...</div>
    }

    return (
        <div className="smart-wash-quick-pickup-summary">
            <h1>Quick Pickup Summary</h1>
            <div className="quick-pickup-summarythank-you-message">
                <span className="material-symbols-outlined">
                    check_circle
                </span>
                <h2>Thank You for Ordering!</h2>
                <p><strong>Order ID  : </strong> {id}</p>  
            </div>

            <div className="quick-pickup-summary-item">
                <h2>Personal Details</h2>
                <p>Name: {user?.fullName}</p>
                <p>Phone: {user?.phoneNumber}</p>
            </div>
            <div className="quick-pickup-summary-item">
                <h2>Address</h2>
                <p>{order.shippingAddress.houseNumber}</p>
                <p>{order.shippingAddress.streetLineOne}</p>
                <p>{order.shippingAddress.streetLineTwo}</p>
                <p>{order.shippingAddress.landmark}</p>
                <p>{order.shippingAddress.city}, {order.shippingAddress.state}</p>
                <p>{order.shippingAddress.pincode}</p>
            </div>
            <div className="quick-pickup-summary-item">
                <h2>Expected Number of Clothes</h2>
                <p>{order.expectedClothsQuantity}</p>
            </div> 

            <button onClick={() => navigate('/orders')} className='quick-pickup-summary-see-all-orders-btn'>See All Orders</button>
        </div>
    );
};

export default QuickPickupSummary;
