import { Route, Routes, Navigate } from "react-router-dom";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import { useEffect, useState, lazy, Suspense } from "react";
// components
import NavbarBottom from "../default components/NavbarBottom";
import NavbarBottomTwo from "../default components/NavbarBottomTwo";
import PartnerSlider from "../default components/PartnerSlider";
import Notification from "../default pages/notifications/Notification";
import ColorsComponent from "../default components/ColorsComponent";
import PGLoginSignUp from "../firebase files/login/PGLoginSignUp";
// import PhoneLoginSignup from "../firebase files/login/PhoneLoginSignup";
import Cart from "../default components/Cart";

// admin
import PGAdminDashboard from "../allRoles/admin/PGAdminDashboard";
import PGAdminUser from "../allRoles/admin/PGAdminUser";
import AppSettings from "../allRoles/admin/PGAppSettings/AppSettings";
import AddPhoto from "../firebase files/AddPhoto";
import AddDocument from "../firebase files/AddDocument";
import ProductDetails from "../default pages/products details/ProductDetails";
import BookingList from "../default pages/bookings/BookingList";
import TicketDetail from "../chatboard/TicketDetail";
import CreateTicket from "../chatboard/CreateTicket";

// styles
import "../App.css";
import AdminSettings from "../allRoles/admin/AdminSettings";
import CustomerDashboard from "../allRoles/customer/dashboard/CustomerDashboard";
import PGProducts from "../default pages/products/PGProducts";
import PGFaq from "../default pages/faq/PGFaq";
import PrivacyPolicy from "../default pages/privacy policy/PrivacyPolicy";
import ReturnPolicy from "../default pages/return policy/ReturnPolicy";
import PGAboutUs from "../default pages/about us/PGAboutUs";
import TermsAndCondition from "../default pages/terms & condition/TermsAndCondition";
import ContactUs from "../default pages/contact us/ContactUs";
import { useDocument } from "../firebase files/hooks/useDocument";
import PGGallery from "../default pages/gallery/PGGallery";
import FloatingSpeedDial from "../default components/FloatingSpeedDial";
import PGCareer from "../default pages/career/PGCareer";
import PGFeatures from "../default pages/features/PGFeatures";
import PGTeam from "../default pages/team/PGTeam";
import TemplateEditSidePopup from "../default components/TemplateEditSidePopup";
import QRScannerComponent from "../default components/QRScannerComponent";
import PGDashboard from "../allRoles/dashboards/PGDashboard";
import AddCategories from "../allRoles/admin/AddCategories";
import BlogAndArticles from "../default components/BlogAndArticles";
import Address from "../default components/Address";
import CorporateTwoTerms from "../templates/corporate/corporateTwo/corporateTwoPages/CorporateTwoTerms";
import CorporateTwoPolicy from "../templates/corporate/corporateTwo/corporateTwoPages/CorporateTwoPolicy";
import PGAttendance from "../default pages/attendance/PGAttendance";
import PGHrAttendance from "../default pages/attendance/PGHrAttendance";

// IMPORT BOOKINGS PAGE AND COMPONENTS
// import BasicAddBooking from "../default pages/add booking/BasicAddBooking";
import PGAccommodationBooking from "../bookings/accommodation booking/PGAccommodationBooking";
import AccommodationPropertiesDetails from "../bookings/accommodation booking/AccommodationPropertiesDetails";
import AccommodationCheckout from "../bookings/accommodation booking/AccommodationCheckout";
import Checkout from "../default components/Checkout";
import OrderSummary from "../default components/OrderSummary";
import OrdersPage from "../default components/OrdersPage";
import Wishlist from "../default components/Wishlist";
import AccommodationBookingDashboard from "../bookings/accommodation booking/AccommodationBookingDashboard";
import AllContactData from "../default pages/contact us/AllContactData";
import OrderDetails from "../default components/OrderDetails";
import QuickPickup from "../default components/quickPickup/QuickPickup";
import QuickPickupSummary from "../default components/quickPickup/QuickPickupSummary";
import QuickPickupProductAdd from "../default components/quickPickup/QuickPickupProductAdd";
import Reorder from "../default components/reorder/Reorder";

// import AdminRoutes from "../default components/adminPanel/AdminRoutes";
import OnlinePayment from "../default components/OnlinePayment";

// IMPORT COMPONENT  OF FIRESTORE DB DYNAMIC PAGES
import DynamicPages from "../default components/DynamicPages";
import MyGridLayout from "../default components/New page using drag and drop/MyGridLayout";
import CustomerRoutes from "../default components/customerPanel/CustomerRoutes";
import UserDetail from "../default components/userDetail/UserDetail";
import LeaveHistory from "../default components/leaveManagment/LeaveHistory";

function GlobalRoute() {
  const { user } = useAuthContext();
  const upcomingBookings = 6;
  const confirmedBookings = 100;
  const cancelledBookings = 2;

  // lazy loading try
  const PGProducts = lazy(() => import("../default pages/products/PGProducts"));
  const AdminRoutes = lazy(() =>
    import("../default components/adminPanel/AdminRoutes")
  );
  const OrdersPage = lazy(() => import("../default components/OrdersPage"));

  const [currentModeStatus, setCurrentModeStatus] = useState("dark");

  const { document: dbTextContentDocuments, error: dbTextContentError } =
    useDocument("settings", "PWA");

  const { document: dbDisplayModeDocuments, error: dbDisplayModeError } =
    useDocument("settings", "mode");

  const { document: dbLaunchingSoonDocuments, error: dbLaunchingSoonError } =
    useDocument("settings", "launchingPage");

  const { document: dbPagesControlDocuments, error: dbPagesControlError } =
    useDocument("settings", "pagesControl");

  const { document: loginPopupDocuments, error: loginPopupError } = useDocument(
    "settings",
    "LoginPopup"
  );

  const { document: dbAppTypeDocuments, error: dbAppTypeError } = useDocument(
    "settings",
    "AppType"
  );

  const { document: dbTemplatesDocuments, error: dbTemplatesError } =
    useDocument("settings", "templates");

  // const [isLoading, setIsLoading] = useState(true);
  const [activeTemplate, setActiveTemplate] = useState(null);

  useEffect(() => {
    const fetchActiveTemplate = async () => {
      try {
        // Access the activeTemplate value from the fetched document
        if (dbTemplatesDocuments) {
          setActiveTemplate(dbTemplatesDocuments.activeTemplate);
        }
      } catch (error) {
        console.error("Error fetching active template:", error);
      } finally {
        // setIsLoading(false);
      }
    };

    fetchActiveTemplate();
  }, [dbTemplatesDocuments]);

  // PWA CODE START
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  function startChromeInstall() {
    if (deferredPrompt) {
      deferredPrompt.prompt();
    }
    setinstallPopupDiv(false);
  }

  const [installPopupDiv, setinstallPopupDiv] = useState(true);
  const closeInstallPopup = () => {
    setinstallPopupDiv(false);
  };
  // PWA CODE END

  useEffect(() => {
    //First time set display mode from db to useState variable and also store the same value in localStorage
    if (
      localStorage.getItem("mode") === null ||
      localStorage.getItem("mode") === "null" ||
      localStorage.getItem("mode") === ""
    ) {
      // console.log('dbdisplaymode: ', dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode)
      setCurrentModeStatus(
        dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode
      );
      localStorage.setItem(
        "mode",
        dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode
      );
    }
    // Function to handle changes in localStorage
    const handleStorageChange = () => {
      const storedValue = localStorage.getItem("mode");
      setCurrentModeStatus(storedValue);
    };

    // Call the function initially
    handleStorageChange();

    // Listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dbDisplayModeDocuments]);

  return (
    <>
      {/* <Navbar /> */}
      {/* <TemplateEditSidePopupz /> */}
      {/* <QRScannerComponent/> */}
      <div className={"global-route-full-content"}>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/adminsettings" element={<AdminSettings />}></Route>
            <Route path="/add-categories" element={<AddCategories />}></Route>
            {/* <Route path="/dashboard" element={<PGDashboard />}></Route> */}

            <Route
              path="/customerdashboard"
              element={
                <CustomerDashboard
                  upcomingBookings={upcomingBookings}
                  confirmedBookings={confirmedBookings}
                  canceledBookings={cancelledBookings}
                />
              }
              // element={
              //   user && user ? (
              //     <CustomerDashboard
              //       upcomingBookings={upcomingBookings}
              //       confirmedBookings={confirmedBookings}
              //       canceledBookings={cancelledBookings}
              //     />
              //   ) : (
              //     <Navigate to="/login" />
              //   )
              // }
            />
            <Route
              path="/notifications"
              element={
                (user &&
                  user.status === "active" &&
                  dbAppTypeDocuments &&
                  dbAppTypeDocuments.apptype === "liteplus") ||
                (dbAppTypeDocuments && dbAppTypeDocuments.apptype === "pro") ? (
                  <Notification />
                ) : (
                  <Navigate to="/login" />
                )
              }
            ></Route>
            <Route path="/products" element={<PGProducts />} />
            <Route
              path="/product-details/:productId"
              element={<ProductDetails />}
            />

            <Route
              path="/accommodation-booking"
              element={<PGAccommodationBooking />}
            ></Route>
            <Route
              path="/accommodation-details"
              element={<AccommodationPropertiesDetails />}
            ></Route>
            <Route
              path="/accommodation-checkout"
              element={<AccommodationCheckout />}
            ></Route>
            <Route
              path="/accommodation-dashboard"
              element={<AccommodationBookingDashboard />}
            ></Route>

            {/* Dynamic Route to Handle Document ID */}
            <Route path="/:id" element={<DynamicPages />} />

            <Route path="/new-dragging-page" element={<MyGridLayout />}></Route>

            <Route
              path="/career"
              element={
                dbPagesControlDocuments &&
                dbPagesControlDocuments.career === true && <PGCareer />
              }
            ></Route>

            <Route path="/our-team" element={<PGTeam />}></Route>

            <Route
              path="/features"
              element={
                dbPagesControlDocuments &&
                dbPagesControlDocuments.features === true && <PGFeatures />
              }
            ></Route>

            {/* <Route
            path="/aboutus"
            element={<PGAboutUs />}
          ></Route> */}

            <Route path="/blog-articles" element={<BlogAndArticles />}></Route>

            {/* <Route
            path="/admindashboard"
            element={
              user && user.role === "admin" ? (
                <PGAdminDashboard />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route> */}
            <Route
              path="/sitesettings"
              element={
                user && user.role === "admin" ? (
                  <AppSettings />
                ) : (
                  <Navigate to="/login" />
                )
              }
            ></Route>
            <Route
              path="/cart"
              element={user && user ? <Cart /> : <Navigate to="/login" />}
            ></Route>

            <Route
              path="/privacy-policy"
              element={<CorporateTwoPolicy />}
            ></Route>
            <Route
              path="/term-condition"
              element={<CorporateTwoTerms />}
            ></Route>
            <Route path="/enquries-list" element={<AllContactData />}></Route>

            <Route path="/checkout" element={<Checkout />} />

            <Route path="/ordersummary/:id" element={<OrderSummary />} />

            <Route
              path="/orders"
              element={user && user ? <OrdersPage /> : <Navigate to="/login" />}
            />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/address" element={<Address />} />

            <Route path="/order-details/:id" element={<OrderDetails />} />

            <Route path="/admin-panel/*" element={<AdminRoutes />} />

            <Route path="/customer-panel/*" element={<CustomerRoutes />} />

            <Route path="/onlinepayment" element={<OnlinePayment />} />

            <Route path="/quick-pickup" element={<QuickPickup />} />
            <Route path="/quick-pickup-summary/:id" element={<QuickPickupSummary />} />
            <Route path="/quick-pickup-product-add/:id" element={<QuickPickupProductAdd />} />

            <Route path="/reorder/:id" element={<Reorder/>}/>

            <Route
              path="/all-users"
              element={
                user && user.role === "admin" ? (
                  <PGAdminUser />
                ) : (
                  <Navigate to="/login" />
                )
              }
            ></Route>
            <Route
              path="/user-detail/:userProfileId"
              element={
                user ? (
                  //removed (&& user.role === "admin") by Kaartik on 24th feb
                  <UserDetail />
                ) : (
                  <Navigate to="/login" />
                )
              }
            ></Route>
            <Route
              path="/addphoto"
              element={
                user && user.role === "admin" ? (
                  <AddPhoto />
                ) : (
                  <Navigate to="/login" />
                )
              }
            ></Route>
            <Route
              path="/adddocument"
              element={
                user && user.role === "admin" ? (
                  <AddDocument />
                ) : (
                  <Navigate to="/login" />
                )
              }
            ></Route>

            <Route path="/returnpolicy" element={<ReturnPolicy />}></Route>
            <Route path="/contact" element={<ContactUs />}></Route>
            <Route
              path="/ticketdetail"
              element={
                (user &&
                  user.status === "active" &&
                  dbAppTypeDocuments &&
                  dbAppTypeDocuments.apptype === "liteplus") ||
                (dbAppTypeDocuments && dbAppTypeDocuments.apptype === "pro") ? (
                  <TicketDetail />
                ) : (
                  <Navigate to="/login" />
                )
              }
            ></Route>
            <Route
              path="/createticket"
              element={
                user && user.status === "active" ? (
                  <CreateTicket />
                ) : (
                  <Navigate to="/login" />
                )
              }
            ></Route>
            <Route
              path="/bookinglist"
              element={user ? <BookingList /> : <Navigate to="/login" />}
            ></Route>
            <Route path="/gallery" element={<PGGallery />}></Route>
            <Route path="/faq" element={<PGFaq />}></Route>
            <Route path="/partnerslider" element={<PartnerSlider />}></Route>

            <Route path="/login" element={<PGLoginSignUp />}></Route>

            <Route
              path="/leave-history"
              element={user && user ? <LeaveHistory /> : <PGLoginSignUp />}
            ></Route>

            <Route
              path="/attendance"
              element={
                user && user.status === "active" ? (
                  <PGAttendance />
                ) : (
                  <Navigate to="/login" />
                )
              }
            ></Route>

            <Route
              path="/attendance-dashboard"
              element={
                user &&
                (user.role === "hr" ||
                  user.role === "superAdmin" ||
                  user.role === "admin") ? (
                  <PGHrAttendance />
                ) : (
                  <Navigate to="/profile" />
                )
              }
            ></Route>
          </Routes>
        </Suspense>
      </div>
      <NavbarBottomTwo />
      {/* <FooterUpper /> */}
      <FloatingSpeedDial />
      {/* <Footer /> */}
      <ColorsComponent />
    </>
  );
}

export default GlobalRoute;
